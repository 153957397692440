var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative pt-64 pt-md-80" },
    [
      _c(
        "div",
        { staticClass: "filter-wrap" },
        [
          _c(
            "ul",
            { staticClass: "filter-list shipments" },
            _vm._l(_vm.filters, function(filter) {
              return _c(
                "li",
                {
                  key: filter.title,
                  class: { active: _vm.activeTab == filter.id },
                  on: {
                    click: function($event) {
                      $event.preventDefault()
                      return _vm.toggleTab(filter.id)
                    }
                  }
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "btn-filter",
                      attrs: {
                        href: "#",
                        id: filter.id,
                        fields: _vm.computedFileds
                      },
                      on: {
                        click: function($event) {
                          return _vm.toggleTab(filter.id)
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(filter.label || filter.title) + " ")]
                  )
                ]
              )
            }),
            0
          ),
          _c("FilterSidebar", {
            ref: "filter",
            staticClass: "pl-20 pl-lg-0",
            attrs: { warehouses: _vm.warehouses, noText: false }
          })
        ],
        1
      ),
      _c("transition", { attrs: { name: "slide-left" } }, [
        _vm.selectedRows.length > 0
          ? _c("div", { staticClass: "select-bar filters row z-99" }, [
              _c(
                "div",
                {
                  staticClass:
                    "container-fluid d-flex justify-content-between align-items-center h70px"
                },
                [
                  _c(
                    "span",
                    { staticClass: "d-none d-md-flex text-gray-900 wsnw" },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.selectedRows.length) +
                          " Manifests selected "
                      )
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "ml-auto d-none d-md-block" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-8 mr-md-16",
                          on: { click: _vm.clearSelected }
                        },
                        [_vm._v(" Clear ")]
                      ),
                      _vm.selectedRows.length == 1 &&
                      _vm.shipmentCount > 1 &&
                      _vm.activeTab == 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-8 mr-md-16",
                              on: { click: _vm.splitManifest }
                            },
                            [_vm._v(" Split Manifest ")]
                          )
                        : _vm._e(),
                      !_vm.canHideDownload
                        ? _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "secondary",
                                disabled: _vm.downloading
                              },
                              on: { click: _vm.downloadManifests }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-download mr-10 fs16"
                              }),
                              _vm._v(" Download "),
                              _vm.downloading
                                ? _c("b-spinner", { staticClass: "sm" })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "visible-sm d-flex" },
                    [
                      _c(
                        "b-button",
                        {
                          staticClass: "mr-16 fs14 fw400 text-gray-900 h40px",
                          attrs: { size: "xs" },
                          on: { click: _vm.openCancelModal }
                        },
                        [
                          _c("i", {
                            staticClass: "icon-cancel text-blue fs16 mr-6"
                          }),
                          _vm._v(" Cancel ")
                        ]
                      ),
                      _vm.selectedRows.length == 1 &&
                      _vm.shipmentCount > 1 &&
                      _vm.activeTab == 0
                        ? _c(
                            "b-button",
                            {
                              staticClass: "mr-8 mr-md-16",
                              on: { click: _vm.splitManifest }
                            },
                            [_vm._v(" Split Manifest ")]
                          )
                        : _vm._e(),
                      !_vm.canHideDownload
                        ? _c(
                            "b-button",
                            {
                              staticClass: "fw400 fs14 text-gray-900 h40px",
                              attrs: {
                                variant: "secondary",
                                size: "xs",
                                disabled: _vm.downloading
                              },
                              on: { click: _vm.downloadManifests }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-download text-blue fs16 mr-6"
                              }),
                              _vm._v(" Download "),
                              _vm.downloading
                                ? _c("b-spinner", { staticClass: "sm" })
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ],
                    1
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.filterResultsToggle
          ? _c("div", { staticClass: "select-bar d-flex shipments" }, [
              _c(
                "div",
                { staticClass: "container-fluid d-flex align-items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          this.selFilters.length != "0"
                            ? _c(
                                "span",
                                {
                                  staticClass:
                                    "d-none d-md-flex mr-6 text-gray-600 wsnw"
                                },
                                [_vm._v(" Results for: ")]
                              )
                            : _vm._e(),
                          _c(
                            "ul",
                            { staticClass: "d-none d-md-flex result-list" },
                            _vm._l(_vm.selFilters, function(selFilter, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  index != "filtering" && selFilter
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mx-4 my-1",
                                          attrs: { variant: "tag" }
                                        },
                                        [
                                          selFilter.hasOwnProperty("name")
                                            ? _c(
                                                "span",
                                                { staticClass: "wsnw" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(selFilter.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "wsnw text-truncate"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        selFilter.includes(
                                                          "\n"
                                                        ) ||
                                                          selFilter.includes(
                                                            ","
                                                          )
                                                          ? "Multiple"
                                                          : selFilter
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                          _c("i", {
                                            staticClass: "icon-close fs6",
                                            on: {
                                              click: function($event) {
                                                return _vm.removeFilter(index)
                                              }
                                            }
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          this.selFilters.length != "0"
                            ? _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(
                                  " " +
                                    _vm._s(this.selFilters.length) +
                                    " Filters selected. "
                                )
                              ])
                            : _c("span", { staticClass: "d-flex d-md-none" }, [
                                _vm._v(" No filters selected. ")
                              ]),
                          _c(
                            "b-link",
                            {
                              staticClass: "ml-24 mr-12 vam",
                              on: {
                                click: function($event) {
                                  return _vm.clearFilters()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-gray-900 wsnw" },
                                [_vm._v("Clear filters")]
                              ),
                              _c("i", {
                                staticClass:
                                  "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                              })
                            ]
                          )
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-toggle",
                                  rawName: "v-b-toggle.sidebarFilters",
                                  modifiers: { sidebarFilters: true }
                                }
                              ],
                              staticClass:
                                "d-none text-gray-900 d-md-flex ml-8",
                              attrs: { variant: "secondary" }
                            },
                            [
                              _vm._v(" Filters "),
                              _c("i", {
                                staticClass: "icon-filter text-gray-600 pl-10"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "light-header",
                attrs: {
                  items: _vm.items,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  id: "manifests-table"
                },
                on: { "row-clicked": _vm.rowClicked },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(id)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.id) + " ")]
                      }
                    },
                    {
                      key: "cell(date)",
                      fn: function(data) {
                        return [
                          _c("div", { staticClass: "w74 d-block" }, [
                            _vm._v(
                              " " + _vm._s(_vm._f("date")(data.item.date)) + " "
                            )
                          ])
                        ]
                      }
                    },
                    {
                      key: "head(selected)",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-checkbox", {
                                attrs: {
                                  indeterminate:
                                    _vm.allSelected &&
                                    _vm.selectedRows.length != _vm.items.length
                                },
                                on: { change: _vm.toggleAllRows },
                                model: {
                                  value: _vm.allSelected,
                                  callback: function($$v) {
                                    _vm.allSelected = $$v
                                  },
                                  expression: "allSelected"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(selected)",
                      fn: function(ref) {
                        var item = ref.item
                        var key = ref.field.key
                        return [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-center align-items-center"
                            },
                            [
                              _c("b-checkbox", {
                                attrs: { disabled: item.shipments == 0 },
                                on: { input: _vm.checkboxPersist },
                                model: {
                                  value: item[key],
                                  callback: function($$v) {
                                    _vm.$set(item, key, $$v)
                                  },
                                  expression: "item[key]"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(action)",
                      fn: function(data) {
                        return [
                          _c(
                            "b-button",
                            {
                              attrs: {
                                variant: "primary btn-xs",
                                disabled: data.item.shipments == 0
                              },
                              on: {
                                click: function($event) {
                                  return _vm.downloadOne(
                                    data.item.id,
                                    data.item.carrier,
                                    data.item.status
                                  )
                                }
                              }
                            },
                            [
                              _c("i", {
                                staticClass: "icon-download fs13 mr-6"
                              }),
                              _vm._v(" Download ")
                            ]
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(status)",
                      fn: function(data) {
                        return [
                          _c("div", [
                            _c(
                              "span",
                              { staticClass: "tag", class: data.item.status },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("capitalize")(data.item.status)
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ])
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  4287229252
                )
              })
            ],
            1
          )
        : _c(
            "ul",
            {
              staticClass: "table-cards table-cards--shipments d-flex d-md-none"
            },
            _vm._l(_vm.items, function(item) {
              return _c("li", { key: item.id }, [
                _c(
                  "div",
                  { staticClass: "d-flex justify-content-between w-100" },
                  [
                    _c(
                      "span",
                      {
                        staticClass: "d-block fw500 pb-8 cp",
                        on: {
                          click: function($event) {
                            return _vm.goShipment(item.id)
                          }
                        }
                      },
                      [_vm._v(" Manifest " + _vm._s(item.id) + " ")]
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "d-flex align-items-end justify-content-between"
                      },
                      [
                        _c(
                          "b-button",
                          {
                            attrs: {
                              disabled: item.shipments == 0,
                              variant: "primary btn-xs"
                            },
                            on: {
                              click: function($event) {
                                return _vm.downloadOne(item.id)
                              }
                            }
                          },
                          [
                            _c("i", { staticClass: "icon-download fs12 mr-6" }),
                            _vm._v(" Download ")
                          ]
                        )
                      ],
                      1
                    )
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex align-items-end justify-content-between w-100 mb-12"
                  },
                  [
                    _c("div", { staticClass: "info" }, [
                      _c("span", { staticClass: "carrier-mode" }, [
                        _vm._v(" " + _vm._s(item.mode) + " ")
                      ]),
                      _c("span", { staticClass: "carrier-name" }, [
                        _vm._v(" " + _vm._s(item.carrier) + " ")
                      ])
                    ]),
                    _c("span", [_vm._v(_vm._s(item.shipments) + " shipments")])
                  ]
                ),
                _c("span", [_vm._v(" " + _vm._s(item.pickupAddress) + " ")]),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex w-100 align-items-end justify-content-between"
                  },
                  [
                    _c("span", { staticClass: "fs12 lh16 text-gray-600" }, [
                      _vm._v(" " + _vm._s(_vm._f("date")(item.date)) + " ")
                    ]),
                    _c("span", { staticClass: "tag", class: item.status }, [
                      _vm._v(
                        " " + _vm._s(_vm._f("capitalize")(item.status)) + " "
                      )
                    ])
                  ]
                )
              ])
            }),
            0
          ),
      _c("div", { staticClass: "pagination-bar" }, [
        _c("div", { staticClass: "container-fluid" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-4" }, [
              _c(
                "div",
                { staticClass: "d-inline-flex wsnw align-items-center" },
                [
                  _c(
                    "div",
                    { staticClass: "d-block w50px" },
                    [
                      _c("multiselect", {
                        staticClass: "pagination-items__pp",
                        attrs: {
                          searchable: false,
                          "show-labels": false,
                          "allow-empty": false,
                          options: _vm.pageOptions
                        },
                        on: { input: _vm.setPageNumber },
                        model: {
                          value: _vm.perPage,
                          callback: function($$v) {
                            _vm.perPage = $$v
                          },
                          expression: "perPage"
                        }
                      })
                    ],
                    1
                  ),
                  _c("span", {
                    staticClass: "pl-8 fs12 lh24 text-gray-600 pagination-items"
                  })
                ]
              )
            ]),
            _c("div", { staticClass: "col-8 text-right" }, [
              _c(
                "div",
                {
                  staticClass: "d-inline-flex ml-auto wsnw align-items-center"
                },
                [
                  _c("p", { staticClass: "fs12 lh24 text-gray-600 pr-14" }, [
                    _vm._v(" Page: " + _vm._s(_vm.currentPage) + " ")
                  ]),
                  _c(
                    "b-button-group",
                    [
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.prevDisabled,
                            variant: "paginate left"
                          },
                          on: { click: _vm.prevPage }
                        },
                        [_c("i", { staticClass: "icon-back fs12" })]
                      ),
                      _c(
                        "b-button",
                        {
                          attrs: {
                            disabled: _vm.nextDisabled,
                            variant: "paginate right"
                          },
                          on: { click: _vm.nextPage }
                        },
                        [_c("i", { staticClass: "icon-arrow fs12" })]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ])
          ])
        ])
      ]),
      _vm.items.length == 0 && !_vm.isBusy ? _c("ResultNotFound") : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }