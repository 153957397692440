<template>
  <div class="manifests">
    <AppTopbar
      title="Manifests"
      buttonText="New Order"
      buttonIconClass="icon-add"
      buttonLink="new"
    />
    <ManifestsTable></ManifestsTable>
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import ManifestsTable from "@/components/ManifestsTable.vue";

export default {
  name: "Manifests",
  title: "Shyplite - Manifests",
  components: {
    AppTopbar,
    ManifestsTable,
  },
};
</script>
