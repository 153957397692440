<template>
	<div class="container-fluid position-relative pt-64 pt-md-80">
		<div class="filter-wrap">
            <ul class="filter-list shipments">
                <li v-for="filter in filters" :key="filter.title" @click.prevent="toggleTab(filter.id)" :class="{ active: activeTab == filter.id }">
                    <a href="#" :id="filter.id" @click="toggleTab(filter.id)" :fields="computedFileds" class="btn-filter">
                        {{ filter.label || filter.title }}
                    </a>
                </li>
            </ul>
            <FilterSidebar
            :warehouses="warehouses"
            :noText="false"
            class="pl-20 pl-lg-0"
            ref="filter"
            ></FilterSidebar>
        </div>

		<transition name="slide-left">
			<div class="select-bar filters row z-99" v-if="selectedRows.length > 0">
				<div class="container-fluid d-flex justify-content-between align-items-center h70px">
					<span class="d-none d-md-flex text-gray-900 wsnw">
						{{ selectedRows.length }} Manifests selected
					</span>
					<div class="ml-auto d-none d-md-block">
						<b-button @click="clearSelected" class="mr-8 mr-md-16">
							Clear
						</b-button>
						<b-button @click="splitManifest" class="mr-8 mr-md-16" v-if="(selectedRows.length == 1) && (shipmentCount > 1) && (activeTab == 0)">
							Split Manifest
						</b-button>
						<b-button variant="secondary" :disabled="downloading" @click="downloadManifests" v-if="!canHideDownload">
							<i class="icon-download mr-10 fs16"></i>
							Download <b-spinner v-if="downloading" class="sm"></b-spinner>
						</b-button>
					</div>
					<div class="visible-sm d-flex">
						<b-button
							@click="openCancelModal"
							size="xs"
							class="mr-16 fs14 fw400 text-gray-900 h40px"
							>
							<i class="icon-cancel text-blue fs16 mr-6"></i>
							Cancel
						</b-button>
						<b-button @click="splitManifest" class="mr-8 mr-md-16" v-if="(selectedRows.length == 1) && (shipmentCount > 1) && (activeTab == 0)">
							Split Manifest
						</b-button>
						<b-button
							@click="downloadManifests"
							variant="secondary"
							size="xs"
							v-if="!canHideDownload"
							:disabled="downloading"
							class="fw400 fs14 text-gray-900 h40px"
							>
							<i class="icon-download text-blue fs16 mr-6"></i>
							Download <b-spinner v-if="downloading" class="sm"></b-spinner>
						</b-button>
					</div>
				</div>
			</div>
		</transition>
		
		<transition name="slide-right">
			<div class="select-bar d-flex shipments" v-if="filterResultsToggle">
				<div class="container-fluid d-flex align-items-center">
					<div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0">
						<div class="d-flex align-items-center">
							<span
							class="d-none d-md-flex mr-6 text-gray-600 wsnw"
							v-if="this.selFilters.length != '0'"
							>
							Results for:
						</span>
						<ul class="d-none d-md-flex result-list">
							<li v-for="(selFilter, index) in selFilters" :key="index">
								<b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering' && selFilter">
									<span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
										{{ selFilter.name }}
									</span>
									<span v-else class="wsnw text-truncate">
										{{ (selFilter.includes('\n') || selFilter.includes(',')) ? 'Multiple': selFilter }}
									</span>
									<i class="icon-close fs6" @click="removeFilter(index)"></i>
								</b-button>
							
							</li>
						</ul>
						<span class="d-flex d-md-none" v-if="this.selFilters.length != '0'">
							{{ this.selFilters.length }} Filters selected.
						</span>
						<span class="d-flex d-md-none" v-else>
							No filters selected.
						</span>
						<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
							<span class="text-gray-900 wsnw">Clear filters</span>
							<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
						</b-link>
						</div>
						<div class="d-flex align-items-center">
							<b-button
							variant="secondary"
							class="d-none text-gray-900 d-md-flex ml-8"
							v-b-toggle.sidebarFilters
							>
								Filters <i class="icon-filter text-gray-600 pl-10"></i>
							</b-button>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<div v-if="this.windowWidth > 767">
			<b-table
			:items="items"
			:fields="fields"
			:per-page="perPage"
			:busy="isBusy"
			sticky-header
			no-border-collapse
			@row-clicked="rowClicked"
			class="light-header"
			id="manifests-table"
			>
			<!-- Table Busy loader -->
			<template #table-busy>
				<div class="text-center text-danger my-2">
					<b-spinner class="align-middle"></b-spinner>
					<strong>Loading...</strong>
				</div>
			</template>
			<template v-slot:cell(id)="data">
				{{ data.item.id }}
			</template>
			<!-- A custom formatted data column cell for field 'Date' -->
			<template v-slot:cell(date)="data">
				<div class="w74 d-block">
					{{ data.item.date | date }}
				</div>
			</template>
			<!-- A custom formatted header cell for field 'selected' -->
			<template v-slot:head(selected)>
				<div class="d-flex justify-content-center align-items-center">
					<b-checkbox
					v-model="allSelected"
					:indeterminate="allSelected && selectedRows.length!=items.length"
					@change="toggleAllRows"
					></b-checkbox>
				</div>
			</template>

			<!-- Example scoped slot for select state illustrative purposes -->
			<template v-slot:cell(selected)="{ item, field: { key } }">
				<div class="d-flex justify-content-center align-items-center">
					<b-checkbox @input="checkboxPersist" v-model="item[key]" :disabled="item.shipments == 0"></b-checkbox>
				</div>
			</template>
			<!-- A custom formatted data column cell for field 'Action' -->
			<template v-slot:cell(action)="data">
				<b-button variant="primary btn-xs" :disabled="data.item.shipments==0" @click="downloadOne(data.item.id, data.item.carrier, data.item.status)">
					<i class="icon-download fs13 mr-6"></i>
					Download
				</b-button>
			</template>

			<!-- A custom formatted data column cell for field 'Value' -->
			<template v-slot:cell(status)="data">
				<div>
					<span class="tag" :class="data.item.status">
						{{ data.item.status | capitalize }}
					</span>
				</div>
			</template>
		</b-table>
		</div>

		<ul class="table-cards table-cards--shipments d-flex d-md-none" v-else>
			<li class="" v-for="item in items" :key="item.id">
				<div class="d-flex justify-content-between w-100">
					<span class="d-block fw500 pb-8 cp" @click="goShipment(item.id)">
						Manifest
						{{ item.id }}
					</span>
					<div class="d-flex align-items-end justify-content-between">
						<b-button @click="downloadOne(item.id)" :disabled="item.shipments==0" variant="primary btn-xs">
							<i class="icon-download fs12 mr-6"></i>
							Download
						</b-button>
					</div>
				</div>
				<div class="d-flex align-items-end justify-content-between w-100 mb-12">
					<div class="info">
						<span class="carrier-mode">
							{{ item.mode }}
						</span>
						<span class="carrier-name">
							{{ item.carrier }}
						</span>
					</div>
					<span>{{ item.shipments }} shipments</span>
				</div>
				<span>
					{{ item.pickupAddress }}
				</span>
				<div class="d-flex w-100 align-items-end justify-content-between">
					<span class="fs12 lh16 text-gray-600">
						{{ item.date | date }}
					</span>

					<span class="tag" :class="item.status">
						{{ item.status | capitalize }}
					</span>
				</div>
			</li>
		</ul>

		<div class="pagination-bar">
			<div class="container-fluid">
				<div class="row">
					<div class="col-4">
						<div class="d-inline-flex wsnw align-items-center">
							<div class="d-block w50px">
								<multiselect
								class="pagination-items__pp"
								v-model="perPage"
								:searchable="false"
								:show-labels="false"
								:allow-empty="false"
								:options="pageOptions"
								@input="setPageNumber"
								></multiselect>
							</div>
							<span
							class="pl-8 fs12 lh24 text-gray-600 pagination-items"
							></span>
						</div>
					</div>
					<div class="col-8 text-right">
						<div class="d-inline-flex ml-auto wsnw align-items-center">
							<p class="fs12 lh24 text-gray-600 pr-14">
								Page: {{ currentPage }}
							</p>

							<b-button-group>
								<b-button
								@click="prevPage"
								:disabled="prevDisabled"
								variant="paginate left"
								>
								<i class="icon-back fs12"></i>
							</b-button>

							<b-button
							@click="nextPage"
							:disabled="nextDisabled"
							variant="paginate right"
							>
							<i class="icon-arrow fs12"></i>
						</b-button>
					</b-button-group>
				</div>
			</div>
		</div>
		</div>
		</div>
		<ResultNotFound v-if="items.length == 0 && !isBusy" />
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import FilterSidebar from "@/components/ManifestFilter";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
	name: "ManifestsTable",
	data() {
		return {
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: parseInt(this.$route.query.page) || 1,
			perPage: parseInt(this.$route.query.offset) || this.$store.state.perPage,
			totalPages: 1,
			prevDisabled: true,
			nextDisabled: false,
			startPage: 1,
			pagesArr: [],
			pagination: {},
			items: [],
			isBusy: true,
			status: true,
			canSplit: true,
			isOpen: false,
			shipments: [],
			checked: 0,
			shipmentCount: 0,
			filterResultsToggle: this.$store.getters.filters.manifest ? true : false,
			allSelected: false,
			selFilters: this.$store.getters.filters.manifest || {},
			warehouses: this.$store.getters.warehouses || {},
			downloading: false,
			canHideDownload: false,
			fields: [
                { key: "selected" },
                { key: "date" },
                { key: "id", label: "Manifest ID" },
                { key: "carrier" },
                { key: "mode" },
                { key: "shipments" },
                { key: "pickupAddress", label: "Warehouse" },
                { key: "status" },
                { key: "action", label: "" },
			],
            filters: [
                {
                    id: 0,
                    title: "Open", 
                },
                {
                    id: 1,
                    title: "Close",
                },
            ],
            activeTab: 0,
		};
	},
	components: {
		Multiselect,
		FilterSidebar,
		ResultNotFound
	},
	created() {
		this.getItems()
	},
	methods: {
        toggleTab(id) {
            this.activeTab = id;
			this.getItems();
        },
        computedFileds() {
            return this.fields;
        },
		checkboxPersist() {
			const prows = this.selectedRows.map(i => i.id);
			const data = this.items.filter(i => i.id == prows[0]);
			if (data.length) {
			 this.shipmentCount = data[0]['shipments'];
			 this.canHideDownload = data[0]['carrier'] == "Shypmax" ? true : false;
			} else {
				this.canHideDownload = false;
			}
			setTimeout(() => {
				this.$store.commit('setGlobal', {'prows':prows})
			})
		},
		splitManifest() {
			this.$router.push(`/manifest/split?page=1&offset=25&manifestID=${this.selectedRows[0]['id']}`)
		},
		toggleModal() {
			this.isOpen = !this.isOpen;
		},
		async getItems(params) {
			try {
				this.isBusy = true
				if(!params) {
					params = {}
				}
				if(Object.keys(this.selFilters).length > 0) {
					let filters = {
						filtering: true,
						carrierID: this.selFilters.carrier ? this.selFilters.carrier.value : '',
						address: this.selFilters.address ? this.selFilters.address.id : '',
						modeType: this.selFilters.modeType ? this.selFilters.modeType.value : '',
						awbNo: this.selFilters.awbNo ? this.selFilters.awbNo: '',
						manifestIDs: this.selFilters.manifestIDs ? this.selFilters.manifestIDs: '',
						dateStart: this.selFilters.dateStart ? this.selFilters.dateStart: '',
						dateEnd: this.selFilters.dateEnd ? this.selFilters.dateEnd: '',
					}
					params = Object.assign({}, params, filters)
				}
				const res = await this.axios.get(`/manifests?page=${this.currentPage}&offset=${this.perPage}&status=${this.activeTab ? 0 : 1}`, {params:params})
				this.items = res.data.manifestData.map(item => {
					let returnItems = {
						id: item.manifestID,
						date: item.manifestDate,
						selected: item.selected,
						carrier: item.carrier,
						mode: item.mode,
						shipments: item.noOfShipments,
						pickupAddress: item.pickupAddress,
						allShipmentIDs: item.allShipmentIDs,
						fulFillCount: item.fulFillCount,
						status: item.status ? "open" : "close",
					};
					const prows = this.$store.getters.global.prows || []
					if(prows.indexOf(item.manifestID)>-1) {
						returnItems.selected = true
					}
					return returnItems;
				});
				this.totalRows = this.items.length;
				this.pagination = res.data.pagination
				this.setPagination();
				if(this.items.length < this.perPage) {
					this.nextDisabled = true
				}
				this.isBusy = false
				this.checkboxPersist();
			} catch (error) {
				this.isBusy = false
				this.nextDisabled = true
			}
		},
		setPagination() {
			this.$router.push(`?page=${this.currentPage}&offset=${this.perPage}`).catch(()=>{})
		},
		pushTotalPages() {
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		setPageNumber() {
			this.currentPage = 1
			this.getItems()
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}

			this.prevDisabled = true;
			if (this.totalPages == 1) {
				this.nextDisabled = true;
			} else if (this.totalPages > 1) {
				this.nextDisabled = false;
			}
		},
		updatePageNumber() {
			this.totalPages = Math.ceil(this.totalRows / this.perPage);
			this.pagesArr = [];
			for (var i = 1; i <= this.totalPages; i++) {
				this.pagesArr.push(i);
			}
		},
		rowClicked(item) {
			this.$router.push(`/shipments?manifestID=${item.id}`)
		}, 
		nextPage() {
			this.currentPage++;
			this.getItems()
		},
		prevPage() {
			this.currentPage--
			this.getItems()
		},
		toggleAllRows() {
			if (this.allSelected == true) {
				this.items.forEach((item) => {
					if(item.shipments > 0 && item.carrier != 'Shypmax') {
						this.$set(item, "selected", true);
					}
					this.allSelected = true;
				});
			} else {
				this.selectedRows.forEach((item) => {
					this.$set(item, "selected", false);
					this.allSelected = false;
				});
			}
		},
		clearSelected() {
			this.selectedRows.forEach((item) => {
				this.$delete(item, "selected");
			});
			this.allSelected = false;
		},
		async downloadManifests() {
			try {
				this.downloading = true
				this.popToast("requested", "Download requested", "Your download should start now.");
				let ids = ''
				let closedIds = []
				this.selectedRows.map(item => {
					closedIds.push(item.id)
					ids +='-'+ item.id
				})
				
				const res = await this.axios.get(`/manifestpdf/${ids.substring(1)}`)
				if(res.data.path) {
					this.items.filter(i => closedIds.indexOf(i.id)>=0).map(item => item.status='close')
					this.download(res.data.path, res.data.name)
				}
				this.clearSelected();
			} catch(e) {
				console.log(e);
			}
			this.downloading = false
		},
		async downloadOne(id, carrier, status) {
			if (carrier == 'DHL') {
				if (status == 'open') {
					try {
						let result = await this.axios.get(`/manifestpdfdhl/${id}`);

						if(result) {
							this.popToast("booked", "Manifest has been successfully generated.", "You will receive an email from DHL shortly.");
						}
					}
					catch(err) {
						console.log(err)
						this.popToast("failed", "Failed.", "DHL Manifest Failed.");
					}
				}
				else {
					this.popToast("booked", "Manifest is already generated.", 'Please check for an email from DHL on your registered email ID');
				}
			}
			else {
				try {
					this.popToast("requested", "Download requested", "Your download should start now.");
					const res = await this.axios.get(`/manifestpdf/${id}`)
					if(res.data.path) {
						const index = this.items.findIndex(i => i.id == id)
						this.items[index].status = 'close'
						this.download(res.data.path, res.data.name)
					}
				} catch(e) {
					console.log(e);
				}
			}
		},
		downloadOrders() {
			this.popToast("requested", "Download requested", "Your download should start now.");
			this.filterResultsToggle = false;
		},
		getFilters() {
			this.currentPage = 1
			this.filterResultsToggle = true;
			this.selFilters = this.$store.getters.filters.manifest;
			this.getItems();
		},
		removeFilter(index) {
			const selFilters = {...this.selFilters}
			if(index == 'dateEnd' || index == 'dateStart') {
				delete selFilters.dateEnd
				delete selFilters.dateStart
				delete this.$refs.filter.selectedFilters.dateEnd
				delete this.$refs.filter.selectedFilters.dateStart
				delete this.$refs.filter.form.date
			}else{
				delete selFilters[index]
				delete this.$refs.filter.selectedFilters[index]
			}
			this.selFilters = selFilters
			this.$store.commit('setFilter', {'manifest': selFilters})
			delete this.$refs.filter.form[index]
			if(Object.keys(this.selFilters).length > 0) {
				this.getItems()
			}else{
				this.clearFilters()
			}
		},
		clearFilters() {
			this.filterResultsToggle = false;
			this.selFilters = {};
			this.$refs.filter.clearFilters()
			this.getItems({filtering: false})
		},
		openCancelModal() {
			this.$refs.cancelOrderModal.isOpen = !this.$refs.cancelOrderModal.isOpen;
		},
		goShipment(id) {
			this.$router.push(`/shipments?manifestID=${id}`)
		},
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
		selectedRows() {
			const items = this.items.filter((item) => item.selected)
			if(items.length>0) {
				this.allSelected = true
			}
			return items
		},
},
watch: {
	currentPage: function() {
		this.prevDisabled = false;
		this.nextDisabled = false;

		if (this.currentPage == 1) {
			this.prevDisabled = true;
		}

		if (this.currentPage == this.totalPages) {
			this.nextDisabled = true;
		}
	},
	selectedRows: function() {
		if (this.selectedRows.length == this.items.length) {
			this.allSelected = true;
		}
	},
},
mounted() {
	this.totalRows = this.items.length;
	this.totalPages = Math.ceil(this.totalRows / this.perPage);
	for (var i = 1; i <= this.totalPages; i++) {
		this.pagesArr.push(i);
	}
},
};
</script>

<style lang="scss" scoped>
.light-header {
    min-height: 18.75rem;
    max-height: calc(100vh - 208px);
}
.tag {
	font-weight: 800;
}  
.modal-body {
    padding: 0 2.5rem 2.5rem 2.5rem;
}
.total-count {
	height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.btn-filter:before {
	background-color: transparent;
}
.filter-list.shipments li:first-child {
	margin-left: 0px;
}
.z-99 {
    z-index: 99 !important;
}
</style>
