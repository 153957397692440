var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "manifests" },
    [
      _c("AppTopbar", {
        attrs: {
          title: "Manifest Split",
          buttonText: "New Order",
          buttonIconClass: "icon-add",
          buttonLink: "new"
        }
      }),
      _c("ManifestSplitTable")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }