var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container-fluid position-relative pt-64 pt-md-80" },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center h70px"
        },
        [
          _c("span", { staticClass: "d-none d-md-flex text-gray-900 wsnw" }, [
            _c("h2", [_vm._v("Manifest ID: " + _vm._s(_vm.manifestID))])
          ]),
          _c(
            "div",
            { staticClass: "d-flex d-md-flex z-index" },
            [
              _vm.shipments.length > 1 &&
              _vm.checked < _vm.shipments.length &&
              _vm.checked > 0
                ? _c("b-button", { on: { click: _vm.saveManifest } }, [
                    _vm._v(" Split Shipments (" + _vm._s(_vm.checked) + ") ")
                  ])
                : _vm._e(),
              _c("FilterSidebar", {
                ref: "filter",
                staticClass: "pl-20 pl-lg-0",
                attrs: { noText: false }
              })
            ],
            1
          )
        ]
      ),
      _c("transition", { attrs: { name: "slide-right" } }, [
        _vm.filterResultsToggle
          ? _c("div", { staticClass: "select-bar d-flex shipments" }, [
              _c(
                "div",
                { staticClass: "container-fluid d-flex align-items-center" },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0"
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "d-flex align-items-center" },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "d-none d-md-flex mr-6 text-gray-600 wsnw"
                            },
                            [_vm._v("Results for:")]
                          ),
                          _c(
                            "ul",
                            { staticClass: "d-none d-md-flex result-list" },
                            _vm._l(_vm.selFilters, function(selFilter, index) {
                              return _c(
                                "li",
                                { key: index },
                                [
                                  index != "filtering" && selFilter
                                    ? _c(
                                        "b-button",
                                        {
                                          staticClass: "mx-4 my-1",
                                          attrs: { variant: "tag" },
                                          on: {
                                            click: function($event) {
                                              return _vm.clearFilters()
                                            }
                                          }
                                        },
                                        [
                                          selFilter.hasOwnProperty("name")
                                            ? _c(
                                                "span",
                                                { staticClass: "wsnw" },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(selFilter.name) +
                                                      " "
                                                  )
                                                ]
                                              )
                                            : _c(
                                                "span",
                                                {
                                                  staticClass:
                                                    "wsnw text-truncate"
                                                },
                                                [
                                                  _vm._v(
                                                    " " +
                                                      _vm._s(
                                                        selFilter.includes(
                                                          "\n"
                                                        ) ||
                                                          selFilter.includes(
                                                            ","
                                                          )
                                                          ? "Multiple"
                                                          : selFilter
                                                      ) +
                                                      " "
                                                  )
                                                ]
                                              ),
                                          _c("i", {
                                            staticClass: "icon-close fs6"
                                          })
                                        ]
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            }),
                            0
                          ),
                          _c(
                            "b-link",
                            {
                              staticClass: "ml-24 mr-12 vam",
                              on: {
                                click: function($event) {
                                  return _vm.clearFilters()
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "text-gray-900 wsnw" },
                                [_vm._v("Clear")]
                              ),
                              _c("i", {
                                staticClass:
                                  "icon-close-variant text-gray-900 fw600 ml-8 fs8"
                              })
                            ]
                          )
                        ],
                        1
                      )
                    ]
                  )
                ]
              )
            ])
          : _vm._e()
      ]),
      this.windowWidth > 767
        ? _c(
            "div",
            [
              _c("b-table", {
                staticClass: "light-header",
                attrs: {
                  items: _vm.shipments,
                  fields: _vm.fields,
                  "per-page": _vm.perPage,
                  busy: _vm.isBusy,
                  "tbody-tr-class": _vm.highlightSelectedRow,
                  "sticky-header": "",
                  "no-border-collapse": "",
                  id: "manifests-table"
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "table-busy",
                      fn: function() {
                        return [
                          _c(
                            "div",
                            { staticClass: "text-center text-danger my-2" },
                            [
                              _c("b-spinner", { staticClass: "align-middle" }),
                              _c("strong", [_vm._v("Loading...")])
                            ],
                            1
                          )
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "cell(field1)",
                      fn: function(data) {
                        return [_vm._v(" " + _vm._s(data.item.value) + " ")]
                      }
                    },
                    {
                      key: "cell(selected)",
                      fn: function(data) {
                        return [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: data.item.selected,
                                expression: "data.item.selected"
                              }
                            ],
                            attrs: { type: "checkbox", id: data.item.id },
                            domProps: {
                              checked: Array.isArray(data.item.selected)
                                ? _vm._i(data.item.selected, null) > -1
                                : data.item.selected
                            },
                            on: {
                              click: function($event) {
                                return _vm.updateSelected()
                              },
                              change: function($event) {
                                var $$a = data.item.selected,
                                  $$el = $event.target,
                                  $$c = $$el.checked ? true : false
                                if (Array.isArray($$a)) {
                                  var $$v = null,
                                    $$i = _vm._i($$a, $$v)
                                  if ($$el.checked) {
                                    $$i < 0 &&
                                      _vm.$set(
                                        data.item,
                                        "selected",
                                        $$a.concat([$$v])
                                      )
                                  } else {
                                    $$i > -1 &&
                                      _vm.$set(
                                        data.item,
                                        "selected",
                                        $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1))
                                      )
                                  }
                                } else {
                                  _vm.$set(data.item, "selected", $$c)
                                }
                              }
                            }
                          })
                        ]
                      }
                    },
                    {
                      key: "cell(customer)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm.getCustomer(data.item.customer)) +
                              " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(city)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " + _vm._s(_vm.getCity(data.item.customer)) + " "
                          )
                        ]
                      }
                    },
                    {
                      key: "cell(created)",
                      fn: function(data) {
                        return [
                          _vm._v(
                            " " +
                              _vm._s(_vm._f("date")(data.item.created)) +
                              " "
                          )
                        ]
                      }
                    }
                  ],
                  null,
                  false,
                  3068843606
                )
              })
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }