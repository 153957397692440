<template>
  <div class="manifests">
    <AppTopbar
      title="Manifest Split"
      buttonText="New Order"
      buttonIconClass="icon-add"
      buttonLink="new"
    />
    <ManifestSplitTable></ManifestSplitTable>
  </div>
</template>

<script>
import AppTopbar from "@/components/layout/AppTopbar.vue";
import ManifestSplitTable from "@/components/ManifestSplitTable.vue";

export default {
  name: "ManifestSplit",
  title: "Shyplite - Manifest Split",
  components: {
    AppTopbar,
    ManifestSplitTable,
  },
};
</script>
