<template>
	<div class="filter-sidebar">
		<div class="divider mx-20 vam"></div>
		<b-button v-b-toggle.sidebarFilters class="text-gray-900" id="side-btn">
			<span v-if="this.noText === false">
				Find
			</span>
			<i
			v-if="this.noText === false"
			class="icon-filter text-gray-600 pl-10"
			></i>
			<i v-if="this.noText != false" class="icon-filter text-gray-600"></i>
		</b-button>
		<b-sidebar
		id="sidebarFilters"
		right
		backdrop
		backdrop-variant="dark"
		sidebar-class="filter-side"
		no-header
		shadow
		v-model="sidebarVisible"
		>
		<div class="d-flex flex-column h100vh px-36 pb-32 pt-36">
			<div class="header d-flex align-items-center pb-40">
				<i class="icon-filter text-gray-600 fs24 pr-16"></i>
				<h2 class="text-gray-900">Find</h2>
				<b-button variant="rounded ml-auto" v-b-toggle.sidebarFilters>
					<i class="icon-close-variant"></i>
				</b-button>
			</div>

			<b-form-group>
			<multiselect
				id="name"
				v-model="form.name"
				:options="dropdown"
				label="name"
				title="Find By"
				:searchable="false"
				:show-labels="false"
				placeholder="Find By"
				class="mb-17"
				@input="addFilter({name:form.name})"
				></multiselect>

				<b-form-textarea
				class="mb-17"
				rows="1"
				max-rows="5"
				title="Search by Order ID, Tracking ID, AWB Number"
				placeholder="Enter values separated by comma"
				v-model="form.value"
				@input="addFilter({value:form.value})"
				></b-form-textarea>
			</b-form-group>

			<div class="footer mt-auto text-right pb-32 pt-8">
				<b-link
				v-if="this.hasFilters"
				class="secondary fw500 mr-20"
				@click="clearFilters()"
				>
				Clear
			</b-link>
			<b-button
			:disabled="!this.hasFilters"
			variant="primary"
			@click="applyFilter"
			>
			Apply
		</b-button>
	</div>
</div>
</b-sidebar>
</div>
</template>

<script>
import DatePicker from "vue2-datepicker";
import Multiselect from "vue-multiselect";

export default {
	name: "FilterSidebar",
	props: [
	'noText'
	],
	data() {
		return {
			sidebarVisible: false,
			form: {},
			dropdown: [{name:'OrderID', id:"importedID"}, {name:'AWB', id:"awbNo"}, {name:'ShipmentID', id:"id"}],
			selectedFilters: this.$store.getters.filters.manifestsSplit || {},
		};
	},
	components: {
		DatePicker,
		Multiselect,
	},
	created() {
		this.form = Object.assign({}, this.$store.getters.filters.manifestsSplit)
	},
	methods: {
		async applyFilter(event) {
			this.sidebarVisible = false;
			this.$store.commit('setFilter', {'manifestsSplit': this.selectedFilters})
			this.$parent.getFilters();
		},
		addFilter(filter) {
			if(Object.values(filter)[0]) {
				this.selectedFilters = {...this.selectedFilters, ...filter};
			}else {
				const temp = this.selectedFilters;
				delete temp[Object.keys(filter)[0]]
				this.selectedFilters = Object.assign({}, temp);
			}
		},
		clearFilters() {
			const filter = this.form;
			this.form = {}
			this.selectedFilters = {};
			this.$store.commit('setFilter', {})
			if(Object.keys(filter).length>0) {
				this.$parent.clearFilters();
			}
		},
	},
	computed: {
		hasFilters() {
			if (Object.keys(this.selectedFilters).length>0) {
				return true;
			} else {
				return false;
			}
		},
	},
};
</script>

<style lang="scss">
.filter-sidebar {
	display: flex;
	align-items: center;
	white-space: nowrap;
	@include media-breakpoint-down(md) {
		.divider {
			display: none;
		}
	}
	@include media-breakpoint-down(sm) {
		button {
			i {
				margin: 0 !important;
				padding: 0 !important;
			}
			span {
				display: none;
			}
		}
		.b-sidebar-body {
			height: calc(100% - 86px);
		}
	}

	&.billing-sidebar {
		position: relative !important;
		top: 0;
		right: 0;
		z-index: 8;
	}
}
input.multiselect__input{
	height:auto;
	padding:0;
}
.tag.close{
	float: left;
}
</style>
