<template>
	<div class="container-fluid position-relative pt-64 pt-md-80">
		<div class="d-flex justify-content-between align-items-center h70px">
			<span class="d-none d-md-flex text-gray-900 wsnw">
				<h2>Manifest ID: {{manifestID}}</h2>
			</span>
			<div class="d-flex d-md-flex z-index">
				<b-button @click="saveManifest" v-if="(shipments.length>1) && (checked<shipments.length) && (checked>0)" >
					Split Shipments ({{checked}})
				</b-button>
				<FilterSidebar
				:noText="false"
				class="pl-20 pl-lg-0"
				ref="filter"
				></FilterSidebar>
			</div>
		</div>

		<transition name="slide-right">
			<div class="select-bar d-flex shipments" v-if="filterResultsToggle">
				<div class="container-fluid d-flex align-items-center">
					<div class="d-flex w-100 align-items-center justify-content-between pt-8 pt-md-0">
						<div class="d-flex align-items-center">
							<span
							class="d-none d-md-flex mr-6 text-gray-600 wsnw"
							>Results for:</span>
							<ul class="d-none d-md-flex result-list">
								<li v-for="(selFilter, index) in selFilters" :key="index">
									<b-button class="mx-4 my-1" variant="tag" v-if="index!='filtering' && selFilter" @click="clearFilters()">
										<span v-if="selFilter.hasOwnProperty('name')" class="wsnw">
											{{ selFilter.name }}
										</span>
										<span v-else class="wsnw text-truncate">
											{{ (selFilter.includes('\n') || selFilter.includes(',')) ? 'Multiple': selFilter }}
										</span>
										<i class="icon-close fs6"></i>
									</b-button>
								</li>
							</ul>
							<b-link @click="clearFilters()" class="ml-24 mr-12 vam">
								<span class="text-gray-900 wsnw">Clear</span>
								<i class="icon-close-variant text-gray-900 fw600 ml-8 fs8"></i>
							</b-link>
						</div>
					</div>
				</div>
			</div>
		</transition>

		<div v-if="this.windowWidth > 767">
			<b-table
			:items="shipments"
			:fields="fields"
			:per-page="perPage"
			:busy="isBusy"
			:tbody-tr-class="highlightSelectedRow"
			sticky-header
			no-border-collapse
			class="light-header"
			id="manifests-table"
			>
				<template #table-busy>
					<div class="text-center text-danger my-2">
						<b-spinner class="align-middle"></b-spinner>
						<strong>Loading...</strong>
					</div>
				</template>
				<template #cell(field1)="data">
					{{ data.item.value }}
				</template>
				<template v-slot:cell(selected)="data">
					<input type="checkbox" v-model="data.item.selected" v-bind:id="data.item.id" @click="updateSelected()">
				</template>
				<template v-slot:cell(customer)="data">
					{{ getCustomer(data.item.customer) }}
				</template>
				<template v-slot:cell(city)="data">
					{{ getCity(data.item.customer) }}
				</template>
				<template v-slot:cell(created)="data">
					{{ data.item.created | date }}
				</template>
			</b-table>
		</div>
	</div>
</template>

<script>
import Multiselect from "vue-multiselect";
import FilterSidebar from "@/components/ManifestSplitFilter";
import ResultNotFound from "@/components/ResultNotFound.vue";

export default {
	name: "ManifestSplitTable",
	data() {
		return {
			pageOptions: this.$store.state.pageOptions,
			totalRows: 1,
			currentPage: 1,
			perPage: 100,
			manifestID: parseInt(this.$route.query.manifestID),
			totalPages: 1,
			prevDisabled: true,
			nextDisabled: false,
			startPage: 1,
			pagesArr: [],
			pagination: {},
			items: [],
			isBusy: true,
			status: true,
			canSplit: true,
			isOpen: false,
			shipments: [],
			shipmentsStore: [],
			checked: 0,
			filterResultsToggle: this.$store.getters.filters.manifestsSplit ? true : false,
			allSelected: false,
			selFilters: this.$store.getters.filters.manifestsSplit || {},
			downloading: false,
			fields: [
                { key: `selected`, label: "Select"},
                { key: "importedID", label: "Order ID"},
                { key: "created", label: "Date"},
                { key: "id", label: "Shipment ID"},
                { key: "customer"},
                { key: "city"},
                { key: "awbNo"},
			],
            filters: [
                {
                    id: 0,
                    title: "Open", 
                },
                {
                    id: 1,
                    title: "Close",
                },
            ],
            activeTab: 0,
		};
	},
	components: {
		Multiselect,
		FilterSidebar,
		ResultNotFound
	},
	created() {
		this.getShipments(this.manifestID);
	},
	methods: {
		async getShipments(manifestID) {
			this.isBusy = true;
			try {
				const response = await this.axios.get(`/shipments/manifest/${manifestID}`);
				if(response.data.success) {
					const shipments = response.data.data;
					shipments.forEach((shipment) => {
						shipment['selected'] = false;
					});
					this.checked = shipments.length;
					this.shipments = shipments.slice(0);
					this.shipmentsStore = shipments.slice(0);
					this.filterShipment();
				}
				this.isBusy = false;
			} catch (error) {
				this.isBusy = false;
			}
		},
		getCustomer(data) {
			try {
				const customer = JSON.parse(data);
				return customer.name;
			}catch {
				return 'NA';
			}
		}, 
		getCity(data) {
			try {
				const customer = JSON.parse(data);
				return `${customer.city}-${customer.pin}`;
			}catch {
				return 'NA';
			}
		}, 
		updateSelected() {
			setTimeout(() => {
				this.checked = 0;
				this.shipments.forEach((shipment) => {
					if (shipment['selected']) this.checked++;
				});
				this.fields[0]['label'] = `Selected (${this.checked})`;
			});
		},
		async saveManifest() {
			try {
				const result = await this.axios.post(`/save/update/manifest/shipment`, this.shipments);
				if (result.status == 200 && result.data.success) {
		        	this.popToast("booked", "Success!", `A new manifest(${result.data.data.saveResult.insertId}) with ${this.checked} shipments has been generated successfully.`);
					setTimeout(() => {
						this.clearFilters();
						this.$router.push(`/manifests?page=1&offset=25`);
					}, 2000);
				} else {
	            	this.popToast("failed", "Failed!", result.data.error || "An unknown error occured.");
				}
				
			} catch (error) {
				console.log(error);
			}
		},
		getFilters() {
			this.filterResultsToggle = !this.filterResultsToggle;
			this.filterShipment();
		},
		clearFilters() {
			this.filterResultsToggle = !this.filterResultsToggle;
			this.$refs.filter.form = {};
			this.$refs.filter.selectedFilters = {};
			this.selFilters = {};
			this.$store.commit('setFilter', {});
			this.resetShipments();
		},
		highlightSelectedRow(item, type) {
			let selectedClass = '';
			this.selFilters = this.$store.getters.filters.manifestsSplit;
			if (item && this.selFilters && this.selFilters.value && this.selFilters.name) {
				const key = this.selFilters.name.id;
				let value = ((this.selFilters.value)).replace(/ +/g,'').split(',');
				value = [...new Set(value)];
				value.forEach((el) => {
					if ((item[`${key}`]).toString() == (el).toString()) {
						selectedClass = 'b-table-row-selected';
					}
				});
			}
            return selectedClass;
        },
		filterShipment() {
			this.selFilters = this.$store.getters.filters.manifestsSplit;
			if (this.selFilters && this.selFilters.value && this.selFilters.name) {
				const key = this.selFilters.name.id;
				let value = ((this.selFilters.value)).replace(/ +/g,'').split(',');
				value = [...new Set(value)];
				value.forEach((el) => {
					let index = null;
					let match = null;
					for(let i=0;i<this.shipments.length;i++){
						if ((this.shipments[i][`${key}`]).toString() == (el).toString()) {
							index = i;
							match = this.shipments[i];
							break;
						}
					}
					if (index != null && match != null) {
						this.shipments.splice(index, 1);
						this.shipments.unshift(match);
					}
				});
			}
			this.fields[0]['label'] = `Selected (${this.checked})`;
		},
		resetShipments() {
			const selected = this.shipments.filter((shipment) => shipment['selected']);
			this.shipments = this.shipmentsStore.map((shipment) => shipment);
			this.shipments.forEach((shipment) => {
				selected.forEach((el) => {
					if (shipment['id'].toString() == el['id'].toString()) shipment['selected'] = true;
				});
			});
			this.shipments.sort((a, b)=> (a.selected == b.selected ? 1 : -1));
		},
	},
	computed: {
		windowWidth() {
			return this.$store.state.windowWidth;
		},
		selectedRows() {
			const shipments = this.shipments.filter((shipment) => shipment.selected)
			if(shipments.length>0) {
				this.allSelected = true
			}
			return shipments
		},
},
watch: {
	currentPage: function() {
		this.prevDisabled = false;
		this.nextDisabled = false;

		if (this.currentPage == 1) {
			this.prevDisabled = true;
		}

		if (this.currentPage == this.totalPages) {
			this.nextDisabled = true;
		}
	},
	selectedRows: function() {
		if (this.selectedRows.length == this.items.length) {
			this.allSelected = true;
		}
	},
},
mounted() {
	this.totalRows = this.shipments.length;
	this.totalPages = Math.ceil(this.totalRows / this.perPage);
	for (var i = 1; i <= this.totalPages; i++) {
		this.pagesArr.push(i);
	}
},
};
</script>

<style lang="scss" scoped>
.light-header {
    min-height: 18.75rem;
    max-height: calc(100vh - 208px);
}
.tag {
	font-weight: 800;
} 

/deep/ .b-table-row-selected > td {
	color: #006EFF;
}
.z-index {
	z-index: 9999;
}
</style>
